@charset "utf-8";

@import url('https://fonts.googleapis.com/css?family=Oswald|Raleway');

/* Shared template */
html, body {
    margin: 0;
    padding: 0;
    background: #FFFFFF;
    font-family: 'Raleway', 'serif';
}

a {
    color: inherit;
    text-decoration: inherit;
}

.page-content-wrapper {
    max-width: 100rem;
    margin: 0 auto auto auto;
}

/* Introduction */
.primer {
    max-width: 80em;
    margin: 0 auto auto auto;
    padding: 1rem 2.5%;
}

.page-title {
    width: 100%;
}

.page-title__title {
    font-family: Oswald, sans-serif;
    text-transform: uppercase;
    color: #254153;
    border-bottom: solid 1px #254153;
    font-size: 4rem;
}

.introduction-page {
    max-width: 60rem;
    width: 95%;
    margin: 1rem auto auto auto;
}

.about-page {
    max-width: 60rem;
    width: 95%;
    margin: 1rem auto auto auto;
}

.about-page a:link {
    color: #0000EE;
}

.about-page a:visited {
     color: #551A8B;
}
